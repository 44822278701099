<template>
  <div class="page-sub-box">
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <Search
        codeKey="search_type_device_cisco"
        :searchObj="searchType.obj"
        :useKeywordType="true"
        @change-search-type="$_changeSearchType"
        @onSearchClick="$_searchData($event, 'search_type_device_cisco')"
      >
        <template v-slot:before>
          <DxSelectBox
            v-model="searchType.customTypes['cm']"
            :placeholder="$_msgContents('UC.WORD.EXCHANGER')"
            :items="getCmList"
            display-expr="codeNm"
            value-expr="codeValue"
            :styling-mode="stylingMode"
            :width="120"
            :height="30"
            @value-changed="$_changeSearchCustomType('cm', $event)"
          />
          <!-- <DxSelectBox
            v-model="searchType.customTypes['model']"
            :placeholder="$_msgContents('UC.WORD.MODEL')"
            :items="getModelList"
            display-expr="codeNm"
            value-expr="codeValue"
            :styling-mode="stylingMode"
            :width="120"
            :height="30"
            @value-changed="$_changeSearchCustomType('model', $event)"
          /> -->
          <DxSelectBox
            v-model="searchType.customTypes['model']"
            :placeholder="$_msgContents('UC.WORD.MODEL')"
            :items="searchType.modelList"
            display-expr="description"
            value-expr="name"
            :styling-mode="stylingMode"
            :width="120"
            :height="30"
            @value-changed="$_changeSearchCustomType('model', $event)"
          />
          <DxSelectBox
            v-model="searchType.customTypes['status']"
            :placeholder="$_msgContents('UC.WORD.STATUS')"
            :items="getStatusList"
            display-expr="codeNm"
            value-expr="codeValue"
            :styling-mode="stylingMode"
            :width="120"
            :height="30"
            @value-changed="$_changeSearchCustomType('status', $event)"
          />
        </template>
      </Search>
    </div>

    <esp-dx-data-grid :data-grid="dataGrid" ref="deviceGrid" />
  </div>
</template>

<script>
  import Search from '@/components/common/search.vue';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      DxSelectBox,
      Search,
    },
    data() {
      return {
        config: {
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
          },
          gradeList: null,
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {
            searchTexts: {
              value: '',
              // width: '350px',
            },
          },
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            cm: null,
            model: null,
            status: null,
            modelList: null,
          },
          paramsData: null,
        },
        cmList: [],
        dataGrid: {
          excel: {
            // title: '전화 정보 목록',
            title: this.$_msgContents('UC.WORD.DEVICE_INFO', { defaultValue: '전화 정보' }),
          },
          refName: 'deviceGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          // height: '500', // 주석처리시 100%
          height: 'calc(100vh - 350px)', // 주석처리시 100%
          apiActionNm: {},
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            // cellPrepared: true,
          },
          showActionButtons: {
            excel: true, // 엑셀 버튼
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: true,
            sorting: true,
            grouping: true,
            paging: true,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '모델',
              i18n: 'UC.WORD.MODEL',
              dataField: 'model',
              // dataField: 'product',
              width: 100,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporting: true,
              cellTemplate: async (container, options) => {
                const imgTag = document.createElement('img');
                const imgData = this.$_getAttachFileURL(null, options.data.product);
                imgTag.setAttribute('src', imgData);
                container.append(imgTag);
              },
            },
            {
              caption: '장치이름',
              i18n: 'UC.WORD.DEVICE_NAME',
              dataField: 'name',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporting: false,
              cellTemplate: (container, options) => {
                const aTag = document.createElement('a');
                aTag.innerText = options.value;
                aTag.addEventListener('click', async () => {
                  const type = 'device';
                  await this.onSelectData(options.data, type);
                });
                container.append(aTag);
              },
            },
            {
              caption: '교환기',
              i18n: 'UC.WORD.EXCHANGER',
              dataField: 'cmNm',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporting: true,
              calculateDisplayCellValue: rowData => {
                if (rowData?.cmNm) {
                  return this.getCmNm(rowData.cmNm);
                }
                if (rowData.cmNm == null || rowData.cmNm == 'null') {
                  return '';
                }
              },
            },
            {
              caption: '설명',
              i18n: 'COMPONENTS.DESCRIPTION',
              dataField: 'description',
              // width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              allowExporting: true,
            },
            {
              caption: '장치풀',
              i18n: 'UC.WORD.DEVICEPOOL',
              dataField: 'devicepool',
              width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              allowExporting: true,
            },

            {
              caption: '상태',
              i18n: 'UC.WORD.STATUS',
              dataField: 'status',
              width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              allowExporting: true,
            },
            {
              caption: 'IP',
              dataField: 'ipaddress',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporting: true,
            },
            {
              caption: '내선번호',
              i18n: 'UC.WORD.NUMBER',
              dataField: 'number',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporting: true,
              fixed: false,
              fixedPosition: 'left',
              allowExporting: true,
            },
            {
              caption: '레이블',
              i18n: 'UC.WORD.LABEL',
              dataField: 'label',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporting: true,
            },
            {
              caption: '수정일시',
              i18n: 'COMPONENTS.MODIFY_DATE',
              dataField: 'editDt',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateCellValue: this.formatDt,
              allowExporting: true,
            },
            {
              caption: '복사',
              i18n: 'COMPONENTS.COPY',
              dataField: 'supercopy',
              width: 100,
              height: 40,
              alignment: 'center',
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporting: false,
              cellTemplate: async (container, options) => {
                const imgTag = document.createElement('img');
                const imgData = this.$_getAttachFileURL(null, 'supercopy.gif');
                imgTag.setAttribute('src', imgData);
                imgTag.style.cursor = 'pointer';
                imgTag.addEventListener('click', async () => {
                  await this.onSelectData(options.data, 'supercopy');
                });
                container.append(imgTag);
              },
            },
          ],
        },
      };
    },
    computed: {
      /** @description: U_CODE svr_type 가져오기 */
      getCmList() {
        const currentPath = this.$router.currentRoute.path;
        const store = this.$store.getters.getSearchHists[currentPath] || [];
        const params = store[0]?.search;

        const result = this.$_getCode('search_type_device_cm').filter(d => d.delFl === 'N');
        const svrInx = params?.['cm'] ? params['cm'] : result[0].codeValue;
        this.setCustomTypes('cm', svrInx);
        return result;
      },
      //   getModelList() {
      //     const currentPath = this.$router.currentRoute.path;
      //     const store = this.$store.getters.getSearchHists[currentPath] || [];
      //     const params = store[0]?.search;

      //     const result = this.$_getCode('search_type_device_cisco_model').filter(d => d.delFl === 'N');
      //     const model = params?.['model'] ? params['model'] : result[0].codeValue;
      //     console.log(result);
      //     this.setCustomTypes('model', model);
      //     return result;
      //   },
      getStatusList() {
        const currentPath = this.$router.currentRoute.path;
        const store = this.$store.getters.getSearchHists[currentPath] || [];
        const params = store[0]?.search;

        const result = this.$_getCode('search_type_device_status').filter(d => d.delFl === 'N');
        const status = params?.['status'] ? params['status'] : result[0].codeValue;
        this.setCustomTypes('status', status);
        return result;
      },

      /** @description: pageData -> pageSetting apiActionNm 가져오기 */
      getApiActionNm() {
        return this.config.pageSetting.config?.apiActionNm || null;
      },
    },
    methods: {
      /**@description 단말장치 삭제 메서드 */
      async onDeleteDevice() {
        const selectedRowsData = this.$refs.deviceGrid.selectedRowsData;
        if (!selectedRowsData?.length) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        } else {
          const parseData = selectedRowsData.map(item => {
            return { MAC: item.name };
          });
          const params = {
            cmNm: selectedRowsData[0].cmNm,
            list: JSON.stringify(parseData),
            loginId: this.$store.getters.getLoginId,
          };
          const payload = {
            actionname: 'EUC_DEVICE_CISCO_DELETE',
            data: params,
            loading: true,
          };

          let confirm = this.$_msgContents('COMMON.MESSAGE.CMN_CFM_DELETE_SELECTED');

          if (!(await this.$_Confirm(confirm))) {
            return;
          }
          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_DELETE'));
            await this.$refs.deviceGrid.clearSelection();
            await this.$refs.deviceGrid.refreshData();
          } else {
            await this.$_Msg(res.data.header.resMsg);
          }
        }
      },
      /** @description : 라이프사이클 computed에서 this data를 변경하기 위한 메서드 */
      setCustomTypes(key, value) {
        this.searchType.customTypes[key] = value;
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        this.dataGrid.showActionButtons.customButtons = this.getCustomButtons();
        this.selectModelList();
      },
      getCustomButtons() {
        const buttons = [
          {
            widget: 'dxButton',
            options: {
              icon: '',
              text: this.$_msgContents('COMPONENTS.DELETE'),
              elementAttr: { class: 'btn_XS white light_filled trash' },
              // width: 60,
              height: 30,
              onClick: () => {
                this.onDeleteDevice();
              },
            },
            location: 'before',
          },
        ];

        buttons.push({
          widget: 'dxButton',
          options: {
            icon: '',
            text: this.$_msgContents('UC.WORD.DEVICE_SYNC'),
            elementAttr: { class: 'btn_XS default filled' },
            // width: 90,
            height: 30,
            onClick: () => {
              this.onSyncDevice();
            },
          },
          location: 'before',
        });

        return buttons;
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      async mountData() {
        await this.selectCmList();
        await this.selectDataList();
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList(sort = 'regDt,id') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }
            params = { ...params, ...this.searchType.paramsData, ...this.searchType.customTypes };
            /**검색조건이 0(전체) 일경우 파라미터 삭제 */
            if (params?.['cm'] === '0') {
              delete params['cm'];
            }

            if (params?.['model'] === 'model') {
              delete params['model'];
            }
            if (params?.['status'] === '0') {
              delete params['status'];
            }

            const payload = {
              actionname: 'EUC_DEVICE_CISCO_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.editDt) {
          return this.$_commonlib.formatDate(rowData.editDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      /** @description : 단말 정보 상세 페이지 이동 메서드 */
      async onSelectData(data, type) {
        if (type === 'device') {
          //장치정보
          this.$store.commit('setDetailParams', { deviceData: data });
          this.$router.push({ path: '/euc/device/cisco/detail' });
        } else if (type === 'supercopy') {
          //장치복사
          this.$store.commit('setDetailParams', { deviceData: data });
          this.$router.push({ path: '/euc/device/cisco/supercopy' });
        }
      },
      /**@description 교환기 목록 가져오는 메서드 */
      async selectCmList(sort = '+cmOrd') {
        const params = { sort: sort, viewFl: 'Y' };
        const payload = {
          actionname: 'EUC_CM_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          await this.setCmName(res);
        }
      },
      /**@description 교환기 목록에서 불필요한 항목 삭제하는 메서드 */
      setCmName(res) {
        const length = res.data.data?.length;
        if (length > 0) {
          this.cmList = res.data.data;
          for (let i = 0; i < this.cmList.length; i++) {
            delete this.cmList[i].id;
            delete this.cmList[i].cmIp;
            delete this.cmList[i].passwd;
            delete this.cmList[i].pinPasswd;
            delete this.cmList[i].regDt;
            delete this.cmList[i].siteId;
            delete this.cmList[i].tenantId;
            delete this.cmList[i].userId;
            delete this.cmList[i].vender;
            delete this.cmList[i].viewFl;
          }
        }
      },
      /** @description 교환기 이름 한글 변환 메서드
       *  @param {String} cm Name
       *  @returns {String} 한글명
       */
      getCmNm(cm) {
        let cmNm = '';
        for (let i = 0; i < this.cmList.length; i++) {
          if (this.cmList[i].cmNm === cm) {
            cmNm = this.cmList[i].description;
          }
        }
        if (cmNm !== '' && cmNm !== null) {
          return cmNm;
        } else {
          return '';
        }
      },
      /**@description 전화기 동기화 메서드*/
      async onSyncDevice() {
        let confirm = this.$_msgContents('UC.MESSAGE.START_SYNC');

        if (!(await this.$_Confirm(confirm))) {
          return;
        }

        for (const item of this.cmList) {
          const params = { cmNm: item.cmNm, loginId: this.$store.getters.getLoginId };
          const payload = {
            actionname: 'EUC_DEVICE_CISCO_SYNC',
            data: params,
            loading: true,
          };

          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            // await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
            await this.$refs.deviceGrid.getGridInstance.refresh();
          } else {
            if (res.data.data.length == 0) {
              await this.$_Msg(res.data.header.resMsg);
            } else {
              await this.$_Msg('교환기 : ' + item.cmNm + ' ' + this.$_msgContents('COMMON.MESSAGE.CMN_ERROR'));
            }
          }
        }
      },
      async selectModelList(sort = '+modelOrd , +id') {
        let params = { viewFl: 'Y' };
        if (!params.sort) {
          params.sort = sort;
        }

        const payload = {
          actionname: 'EUC_MODEL_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          this.searchType.modelList = res.data.data;
          this.searchType.modelList.unshift({ name: 'model', description: '모델' });
          const model = this.searchType.modelList[0].name;
          this.setCustomTypes('model', model);
        }
      },
    },

    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>
