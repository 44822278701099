<!--
  PACKAGE_NAME : src/pages/euc/device
  FILE_NAME : index.vue.vue
  AUTHOR : jhcho
  DATE : 2024-04-19
  DESCRIPTION : Vender 사에 따라 Device 페이지 다르게 표시
-->
<template>
	<component :is="getComponent" />
</template>
<script>
import AvayaDevice from './avaya';
import CiscoDevice from './cisco';

export default {
	components: {
		AvayaDevice,
		CiscoDevice,
	},
	data() {
		return {
			tabItems: [
				{
					title: 'Avaya 전화 관리',
					component: AvayaDevice,
					vender: ['AVAYA'],
				},
				{
					title: 'Cisco 전화 관리',
					component: CiscoDevice,
					vender: ['CISCO'],
				},
			],
		};
	},
	computed: {
		getVender() {
			return this.$_getSystemData('vender')?.configValue || 'CISCO';
		},
		getComponent() {
			return this.getVender === 'CISCO' ? CiscoDevice : AvayaDevice;
		},
	},
};
</script>

<style scoped></style>
